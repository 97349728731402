import { useEffect } from 'react'
import { navigate } from 'gatsby'

export default (): any => {
  useEffect(() => {
    navigate('/')
  }, [])

  return null
}
